import moment from "moment";
import React, { useState, useEffect } from "react";
import ArchiveRow from "../components/ArchiveRow";
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";
import { BasicScreen } from "../designSystem/BasicScreen";
import Api from "../utils/useApiClient";

const ActiveCompetitions = () => {
  const [competitions, setCompetitions] = useState([]);
  const api = new Api();

  useEffect(() => {
    api.getAllCompetitions().then((res) => {
      let now = moment();
      let activeCompetitions = res.data.results.filter((competition) => {
        return Date.parse(competition.ends) > now;
      });
      setCompetitions(activeCompetitions ?? []);
    });
  }, []);

  return (
    <BasicScreen
      header={<CreateCompetitionHeader text="Active Competitions" />}
    >
      <div className="container-fluid px-3 mt-4">
        <div className="row gy-4">
          {competitions.map((competition, i) => {
            return (
              <div key={i}>
                <hr className="archive_hr" />
                <ArchiveRow pastCompetition={competition} />
              </div>
            );
          })}
        </div>
      </div>
    </BasicScreen>
  );
};

export default ActiveCompetitions;
