import React from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../assets/images/backArrow.svg";

const CreateCompetitionHeader = (props) => {
  const navigate = useNavigate();

  return (
    <div className="create_competition_header py-4 px-3 position-relative">
      <img onClick={() => navigate(-1)} src={backArrow} alt="" />
      <h2 className="fs20 fw800 color2 mb-0 text-center">{props.text}</h2>
    </div>
  );
};

export default CreateCompetitionHeader;
