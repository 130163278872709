import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { COLOR_WHITE, LA_COMIDA_PURPLE } from "../globals/colors";

export const PrimaryButton = styled(Button)`
  background: ${LA_COMIDA_PURPLE};
  color: ${COLOR_WHITE};
  border-radius: 8px;
  width: 100%;
  height: 50px;
  border: none;
  text-transform: none;
  font-size: 17px;
  font-weight: 500;

  :hover {
    background: ${LA_COMIDA_PURPLE};
    color: ${COLOR_WHITE};
  }
`;

export const PrimaryDialogButton = styled(PrimaryButton)`
  width: 60px;
  height: 40px;
`;

export const SecondaryDialogButton = styled(Button)`
  background: transparent;
  color: ${LA_COMIDA_PURPLE};
  border-radius: 8px;
  width: 60px;
  height: 40px;
  border: 1px solid ${LA_COMIDA_PURPLE};

  :hover {
    background: transparent;
    color: ${LA_COMIDA_PURPLE};
    border: 1px solid ${LA_COMIDA_PURPLE};
  }
`;

export const StickyPrimaryButton = styled(PrimaryButton)`
  position: fixed;
  bottom: 24px;
  width: calc(100% - 76px);
`;

export const LinkButton = styled(Button)`
  background: transparent;
  color: ${LA_COMIDA_PURPLE};
  border: none;
  text-transform: none;
`;
