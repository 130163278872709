import * as React from "react";
import calender from "../assets/images/calender.svg";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";


const DatePicker = ({ label, setDate, date }) => {
  const [value, setValue] = React.useState(date);

  const handleChange = (newValue) => {
    setValue(moment(newValue));
    setDate(moment(newValue));
  };

  return (
    <div className="position-relative">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          className="date_picker_custom"
          label={label}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <img className="calender_icon" src={calender} alt="calender" />
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
