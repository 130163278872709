import React, { useState, useEffect } from "react";
import CustomCheckbox from "../components/CustomCheckbox";
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";
import { useNavigate, useLocation } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import Api from "../utils/useApiClient";
import { getFromLocalStorage } from "../utils/useLocalStorage.js";
import { StickyPrimaryButton } from "../designSystem/Buttons";
import { BasicScreen } from "../designSystem/BasicScreen";

const CompetitionProducts = () => {
  const location = useLocation();
  let competitionObject = getFromLocalStorage("competition");
  let mode, id, oldCategories, oldProductsId;
  if (location.state) {
    mode = location.state.mode;
    id = location.state.id;
    if (mode === "edit") {
      oldCategories = competitionObject.categories.map((cat) => cat._id);
      oldProductsId = competitionObject.products.map((prod) => prod._id);
    }
  }
  const api = new Api();

  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState(oldCategories ?? []);
  const [productsId, setProductsId] = useState(oldProductsId ?? []);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const changeSearchTerm = (term) => {
    setSearchTerm(term);
  };

  const search = (val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return val;
    }
  };

  useEffect(() => {
    api.getMenu().then((res) => {
      setMenu(res.data);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let competitionData = {
      name: competitionObject["name"],
      starts: competitionObject["starts"],
      ends: competitionObject["ends"],
      categories: categories,
      products: productsId,
    };
    if (mode === "edit") {
      api.updateCompetition(competitionData, id).then((response) => {
        if (response.status === 200) {
          navigate("/success", { replace: true });
        }
      });
    } else {
      api.createCompetition(competitionData).then((response) => {
        if (response.status === 201) {
          navigate("/success", { replace: true });
        }
      });
    }
  };

  return (
    <BasicScreen
      header={
        <CreateCompetitionHeader
          text="Competition Products"
          back="/competition_date_time"
        />
      }
    >
      <SearchBar onChange={changeSearchTerm} />
      <div className="competition_products_body container-fluid">
        {searchTerm === "" && menu.length > 0 ? (
          <div>
            <h2 className="fs18 color1 fw-bold mt-4">Categories</h2>
          </div>
        ) : (
          ""
        )}
        {menu.filter(search).map((category, j) => {
          return (
            <div key={j} className="col-12 mx-auto my-4">
              <CustomCheckbox
                setProducts={setCategories}
                currentProducts={categories}
                id={category.id}
                status={categories.includes(category.id)}
                key={j}
              >
                {category.name}
              </CustomCheckbox>
            </div>
          );
        })}
        {menu.map((category, key) => {
          return (
            <div key={key}>
              {searchTerm === "" ? (
                <div>
                  <h2 className="fs18 color1 fw-bold mt-5">{category.name}</h2>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                {category.products &&
                  category.products.filter(search).map((content, i) => {
                    return (
                      <div key={i} className="col-12 mx-auto mt-3">
                        <CustomCheckbox
                          setProducts={setProductsId}
                          currentProducts={productsId}
                          id={content.id}
                          status={productsId.includes(content.id)}
                          key={i}
                        >
                          {content.name}
                        </CustomCheckbox>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
        <StickyPrimaryButton onClick={handleSubmit}>
          Start Competition
        </StickyPrimaryButton>
      </div>
    </BasicScreen>
  );
};

export default CompetitionProducts;
