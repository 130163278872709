import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Stack, Typography } from "@mui/material";
import { COLOR_WHITE, LA_COMIDA_PURPLE } from "../globals/colors";

const DashboardButton = ({ text, IconComponent, link }) => {
  const navigate = useNavigate();
  return (
    <StyledButton variant="outlined" onClick={() => navigate(link)}>
      <Stack spacing={1.5} alignItems="center">
        <IconComponent />
        <BoldText>{text}</BoldText>
      </Stack>
    </StyledButton>
  );
};

export default DashboardButton;

const StyledButton = styled(Button)`
  border: 1px solid ${LA_COMIDA_PURPLE};
  boxshadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  padding: 12px 88px;
  width: 100%;
  background: ${COLOR_WHITE};

  :hover {
    background-color: ${COLOR_WHITE};
    border: 1px solid ${LA_COMIDA_PURPLE};
  }
`;

const BoldText = styled(Typography)({
  textTransform: "none",
  color: LA_COMIDA_PURPLE,
  fontWeight: 700,
  fontSize: "18px",
  marginTop: 0,
});
