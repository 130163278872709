import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Api from "../utils/useApiClient";
import { BasicScreen } from "../designSystem/BasicScreen";
import { RouteLink } from "../designSystem/RouteLink";

// COMPONENTS
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";

const ArchivedCompetitionDetail = () => {
  const api = new Api();
  const location = useLocation();
  const { competition } = location.state;
  let startDate = moment(competition.starts);
  let endDate = moment(competition.ends);
  let startDayAndMonth = moment(startDate).format("DD MMM");
  let endDayAndMonth = moment(endDate).format("DD MMM");
  let startTime = moment(startDate).format("HH:mm");
  let endTime = moment(endDate).format("HH:mm");
  let header = `${startDayAndMonth} - ${endDayAndMonth}`;
  let linkToLeaderboard =
    process.env.REACT_APP_LEADERBOARD_URL + `/?competition=${competition.id}`;
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    api.getCompetitionDetails(competition.id).then((res) => {
      let users = res.data.users
        .sort((a, b) => b.points - a.points)
        .slice(0, 3);
      setWinners(users ?? []);
    });
  }, []);

  return (
    <BasicScreen header={<CreateCompetitionHeader text={header} />}>
      <div className="fs18 fw700 color1 text-center pb-2">
        {startTime} - {endTime}
      </div>
      <div className="fs18 fw700 color1 text-center pb-2">
        <a
          href={linkToLeaderboard}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#6869ac" }}
        >
          Go to Leaderboard
        </a>
      </div>
      <div className="fs18 fw700 color1 text-center pb-4">
        <RouteLink
          to="/competition_name"
          style={{ textDecoration: "none", color: "#6869ac" }}
          state={{
            mode: "edit",
            competition: competition,
          }}
        >
          Edit Competition
        </RouteLink>
      </div>

      <div className="px-4">
        {competition.categories.map((category, i) => {
          return (
            <div className="fw600 color2 fs21" key={i}>
              {category.name}
            </div>
          );
        })}
        {competition.products.map((product, i) => {
          return (
            <div className="fw600 color2 fs21" key={i}>
              {product.name}
            </div>
          );
        })}
        <hr className="archive_hr" />
        {winners?.map((person, i) => {
          return (
            <Fragment key={i}>
              <div className="fw800 color2 fs26 row mb-3">
                <div className="col-2">0{i + 1}</div>
                <div className="col">
                  <div>{person.name}</div>
                  <div className="color1 fs14 fw600">{person.score} Points</div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </BasicScreen>
  );
};

export default ArchivedCompetitionDetail;
