import React, { useState, useEffect, Fragment } from "react";
import ArchiveRow from "../components/ArchiveRow";
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";
import { BasicScreen } from "../designSystem/BasicScreen";
import Api from "../utils/useApiClient";

const Archive = () => {
  const [archive, setArchive] = useState([]);
  const api = new Api();

  useEffect(() => {
    api.getAllCompetitions().then((res) => {
      let now = Date.now();
      setArchive(
        res.data.results.filter((competition) => {
          return Date.parse(competition.ends) < now;
        })
      );
    });
  }, []);

  return (
    <BasicScreen header={<CreateCompetitionHeader text="Archive" />}>
      <div className="container-fluid px-3 mt-4">
        <div className="row gy-4">
          {archive.map((pastCompetition, i) => {
            return (
              <Fragment key={i}>
                <hr className="archive_hr" />
                <ArchiveRow pastCompetition={pastCompetition} />
              </Fragment>
            );
          })}
        </div>
      </div>
    </BasicScreen>
  );
};

export default Archive;
