import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CustomCheckbox = ({
  children,
  setProducts,
  id,
  currentProducts,
  status,
}) => {
  const [isActive, setIsActive] = useState(status);

  const addToList = () => {
    setIsActive(!isActive);
    if (!currentProducts.includes(id)) {
      setProducts([...currentProducts, id]);
    }
  };

  const removeFromList = () => {
    setIsActive(!isActive);
    const index = currentProducts.indexOf(id);
    if (index > -1) {
      currentProducts.splice(index, 1);
    }
  };

  return (
    <div
      onClick={!isActive ? addToList : removeFromList}
      style={{
        background: isActive && "#F1ECF9",
        border: isActive && "1px solid #6869AC",
      }}
      className="checkbox_container"
    >
      <FormControlLabel
        onClick={!isActive ? addToList : removeFromList}
        control={
          <Checkbox
            checked={isActive}
            sx={{
              color: "#D1D8DC",
              "&.Mui-checked": {
                color: "#6869ac",
              },
            }}
          />
        }
        label={<label className="check_custom_container">{children}</label>}
      />
    </div>
  );
};

export default CustomCheckbox;
