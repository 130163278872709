import { Container, Stack } from "@mui/material";
import { COLOR_WHITE } from "../globals/colors";

export const BasicScreen = ({ children, header, background = COLOR_WHITE }) => {
  return (
    <Stack spacing={3.5} style={{ background: background }}>
      {header}
      <Container style={{ height: "100vh" }}>{children}</Container>
    </Stack>
  );
};
