import React from "react";
import success from "../assets/images/success.svg";
import {
  getFromLocalStorage,
  removeItemFromLocalStorage,
} from "../utils/useLocalStorage.js";
import { LinkButton } from "../designSystem/Buttons";
import { RouteLink } from "../designSystem/RouteLink";

const CompetitionSuccessful = () => {
  let competitionObject = getFromLocalStorage("competition");
  const competitionName = competitionObject?.name ?? "";
  removeItemFromLocalStorage("competition");

  return (
    <div className="competition_successful_container">
      <img src={success} alt="success" />
      <h2>YAY {competitionName} competition successfully added!</h2>
      <p>In a few minute you will see the compatition on the big screen</p>

      <RouteLink to="/dashboard">
        <LinkButton>Back to Homepage</LinkButton>
      </RouteLink>
    </div>
  );
};

export default CompetitionSuccessful;
