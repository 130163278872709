import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import {
  PrimaryDialogButton,
  SecondaryDialogButton,
} from "../designSystem/Buttons";

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SecondaryDialogButton onClick={() => setOpen(false)}>
          No
        </SecondaryDialogButton>
        <PrimaryDialogButton
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </PrimaryDialogButton>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
