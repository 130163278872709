import React, { useEffect } from "react";
import moment from "moment";
import { ReactComponent as PodiumIcon } from "../assets/images/dashboardIcons/podium.svg";
import { ReactComponent as LightningIcon } from "../assets/images/dashboardIcons/lightning.svg";
import { ReactComponent as ArchiveIcon } from "../assets/images/dashboardIcons/archive.svg";
import HomePageHeader from "../components/HomePageHeader";
import DashboardButton from "../components/DashboardButton";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../utils/useLocalStorage.js";
import { Grid } from "@mui/material";
import { BasicScreen } from "../designSystem/BasicScreen";
import { DASHBOARD_BACKGROUND } from "../globals/colors";

const Dashboard = () => {
  useEffect(() => {
    if (!getFromLocalStorage("competition")) {
      let competitionObject = {
        name: "",
        starts: moment().toISOString(),
        ends: moment().add(1, "days").toISOString(),
      };
      setToLocalStorage("competition", competitionObject);
    }
  }, []);

  return (
    <BasicScreen
      header={<HomePageHeader isLoggedIn={true} />}
      background={DASHBOARD_BACKGROUND}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardButton
            text="New Competition"
            IconComponent={PodiumIcon}
            link="/competition_name"
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardButton
            text="Active Competitions"
            IconComponent={LightningIcon}
            link="/active_competitions"
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardButton
            text="Archive"
            IconComponent={ArchiveIcon}
            link="/archive"
          />
        </Grid>
      </Grid>
    </BasicScreen>
  );
};

export default Dashboard;
