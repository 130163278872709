import React from "react";

// IMAGES
import avatar from "../assets/images/avatar.svg";
import { getFromLocalStorage } from "../utils/useLocalStorage.js";

const HomePageHeader = ({ isLoggedIn }) => {
  const userName = getFromLocalStorage("username");

  return (
    <div className="login_header bg_color1 text-white p-4">
      {isLoggedIn ? (
        <div className="d-flex align-items-center  gap-3">
          <img src={avatar} alt="avatar" />
          <p className="mb-0 fs19 fw600">Hi {userName.name}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HomePageHeader;
