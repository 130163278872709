import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// CSS
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";

// PAGES
import Login from "./pages/Login";
import CompetitionsDashboard from "./pages/CompetitionsDashboard";
import CompetitionName from "./pages/CompetitionName";
import CompetitionDateTime from "./pages/CompetitionDateTime";
import CompetitionProducts from "./pages/CompetitionProducts";
import CompetitionSuccessful from "./pages/CompetitionSuccessful";
import Archive from "./pages/Archive";
import CompetitionDetails from "./pages/CompetitionDetails";
import ActiveCompetitions from "./pages/ActiveCompetitions";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<CompetitionsDashboard />} />
        <Route exact path="/competition_name" element={<CompetitionName />} />
        <Route
          exact
          path="/competition_date_time"
          element={<CompetitionDateTime />}
        />
        <Route
          exact
          path="/competition_products"
          element={<CompetitionProducts />}
        />
        <Route exact path="/success" element={<CompetitionSuccessful />} />
        <Route exact path="/archive" element={<Archive />} />
        <Route
          exact
          path="/past_comp_detail"
          element={<CompetitionDetails />}
        />
        <Route
          exact
          path="/active_competitions"
          element={<ActiveCompetitions />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
