import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../utils/useLocalStorage.js";
import { PrimaryButton } from "../designSystem/Buttons";
import { BasicScreen } from "../designSystem/BasicScreen";
import { RouteLink } from "../designSystem/RouteLink";

const CompetitionName = () => {
  const location = useLocation();
  let competitionObject, mode, id;

  if (location.state) {
    mode = location.state.mode;
    id = location.state.competition.id;
    setToLocalStorage("competition", location.state.competition);
  }
  competitionObject = getFromLocalStorage("competition");

  const [name, setName] = useState(competitionObject?.name ?? "");
  const handleClick = (e) => {
    if (!competitionObject) {
      competitionObject = {
        name: name,
        starts: moment().toISOString(),
        ends: moment().add(1, "days").toISOString(),
      };
    } else {
      competitionObject.name = name;
    }
    setToLocalStorage("competition", competitionObject);
  };

  return (
    <BasicScreen
      header={
        <CreateCompetitionHeader
          className="competition_name_header"
          text="Competition name"
          back="/dashboard"
        />
      }
    >
      <div className="competition_name_body container-fluid">
        <div className="row gy-4">
          <div className="col-10 mx-auto">
            <div className="inp_container ">
              <input
                type="text"
                placeholder="Add competition name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span>Competition Name</span>
            </div>
          </div>
          <div className="col-10 mx-auto">
            <RouteLink
              to="/competition_date_time"
              state={{
                mode: mode,
                id: id,
              }}
            >
              <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
            </RouteLink>
          </div>
        </div>
      </div>
    </BasicScreen>
  );
};

export default CompetitionName;
