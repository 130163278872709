import React from "react";
import { Navigate } from "react-router-dom";
import HomePageHeader from "../components/HomePageHeader";
import LoginForm from "../components/LoginForm";
import { getFromLocalStorage } from "../utils/useLocalStorage.js";
import Api from "../utils/useApiClient";

const Login = () => {
  const api = new Api();

  if (api.getAccessToken()) {
    return <Navigate replace to="/dashboard" />;
  }

  if (getFromLocalStorage("username")) {
    let refreshToken = api.getRefreshToken();
    if (refreshToken) {
      api
        .refreshTokens({
          refreshToken: refreshToken,
        })
        .then((response) => {
          if (response.status === 200) {
            api.setTokens(
              response.data.access.token,
              response.data.access.expires,
              response.data.refresh.token,
              response.data.refresh.expires
            );
          }
        });

      return <Navigate replace to="/dashboard" />;
    }
  }

  return (
    <div className="login_container">
      <HomePageHeader isLoggedIn={false} />
      <LoginForm />
    </div>
  );
};

export default Login;
