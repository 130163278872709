import React, { useState } from "react";
import { Link } from "react-router-dom";
import rightArrow from "../assets/images/rightArrow.svg";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Api from "../utils/useApiClient";
import ConfirmDialog from "../components/ConfirmDialog";
import { RouteLink } from "../designSystem/RouteLink";

const ArchiveRow = ({ pastCompetition }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const api = new Api();
  let startDate = moment(pastCompetition.starts);
  let endDate = moment(pastCompetition.ends);
  let startDayAndMonth = moment(startDate).format("DD MMM");
  let endDayAndMonth = moment(endDate).format("DD MMM");
  let startTime = moment(startDate).format("HH:mm");
  let endTime = moment(endDate).format("HH:mm");

  const deleteCompetiton = () => {
    api
      .deleteCompetition(pastCompetition.id)
      .then(() => window.location.reload(true));
  };

  return (
    <div className="archive_row_container mt-0">
      <div className="wrapper justify-content-between">
        <RouteLink
          to="/past_comp_detail"
          style={{ textDecoration: "none" }}
          state={{
            competition: pastCompetition,
          }}
        >
          <div className="col">
            <div className="fs18 fw700 color1">
              {startDayAndMonth} {startTime} | {endDayAndMonth} {endTime}
            </div>
            {pastCompetition.products.map((product, i) => {
              return (
                <div className="fw600 color2" key={i}>
                  {product.name}
                </div>
              );
            })}
          </div>
        </RouteLink>
        <RouteLink
          to="/competition_name"
          state={{
            mode: "edit",
            competition: pastCompetition,
          }}
        >
          <ModeEditIcon style={{ color: "#6869ac" }} className="ml-3" />
        </RouteLink>
        <div>
          <DeleteIcon
            style={{ color: "#6869ac" }}
            onClick={() => setConfirmOpen(true)}
          />
          <ConfirmDialog
            title="Delete Competition?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deleteCompetiton}
          >
            Are you sure you want to delete {pastCompetition.name}?
          </ConfirmDialog>
        </div>
        <RouteLink
          to="/past_comp_detail"
          style={{ textDecoration: "none" }}
          state={{
            competition: pastCompetition,
          }}
        >
          <img src={rightArrow} alt="" />
        </RouteLink>
      </div>
    </div>
  );
};

export default ArchiveRow;
