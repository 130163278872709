import * as React from "react";
import clock from "../assets/images/clock.svg";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";

const TimePickerComp = ({ label, setTime, time }) => {
  const [value, setValue] = React.useState(time);

  const handleChange = (newValue) => {
    setValue(moment(newValue));
    setTime(moment(newValue));
  };

  return (
    <div className="position-relative">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          className="time_picker_custom"
          label={label}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <img className="clock_icon" src={clock} alt="calender" />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerComp;
