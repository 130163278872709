import React, { useState } from "react";
import { PrimaryButton } from "../designSystem/Buttons";
import Api from "../utils/useApiClient";
import moment from "moment";
import { setToLocalStorage } from "../utils/useLocalStorage.js";

const LoginForm = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loginFailed, setLoginFailed] = useState(false);
  const api = new Api();

  const handleSubmit = (e) => {
    e.preventDefault();

    api
      .login({
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          api.setTokens(
            response.data.tokens.access.token,
            response.data.tokens.access.expires,
            response.data.tokens.refresh.token,
            response.data.tokens.refresh.expires
          );
          setToLocalStorage("username", { name: response.data.user.name });
          setLoginFailed(false);
          let competitionObject = {
            name: "",
            starts: moment().toISOString(),
            ends: moment().add(1, "days").toISOString(),
          };
          setToLocalStorage("competition", competitionObject);
          window.location.href = "/dashboard";
        }
        setPassword("");
        setEmail("");
      })
      .catch((error) => {
        setLoginFailed(true);
      });
  };

  return (
    <div className="container-fluid">
      <p className="text-center py-5 fs18 fw-bold mb-0">Login</p>
      {loginFailed ? (
        <p className="text-center pb-3 fs16 mb-0">
          Invalid username or password
        </p>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <div className="row gy-4">
          <div className="col-10 mx-auto">
            <div className="inp_container ">
              <input
                type="text"
                placeholder="Add your name"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span>Name</span>
            </div>
          </div>
          <div className="col-10 mx-auto">
            <div className="inp_container">
              <input
                type="password"
                placeholder="Letters and numbers"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span>Password</span>
            </div>
          </div>
        </div>
        <div className="col-10 mx-auto mt-4">
          <PrimaryButton onClick={handleSubmit}>Next</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
