import axios from "axios";

const getDomainWithoutSubdomain = (url) => {
  const urlParts = new URL(url).hostname.split(".");

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".");
};

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;
    this.client = this.init();
  }
  init = () => {
    this.api_token = this.getAccessToken();
    let headers = {
      Accept: "application/json",
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
      params: {
        limit: 86450100,
      },
    });
    return this.client;
  };

  getAllCompetitions = () => {
    return this.client.get("/competitions");
  };

  getCompetitionDetails = (id) => {
    return this.client.get(`/competition/${id}/leaderboard`);
  };

  login = (data) => {
    return this.client.post("/auth/login", data);
  };

  getMenu = () => {
    return this.client.get("/categories/menu");
  };

  createCompetition = (data) => {
    return this.client.post("/competitions", data);
  };

  updateCompetition = (data, id) => {
    return this.client.patch(`/competitions/${id}`, data);
  };

  deleteCompetition = (id) => {
    return this.client.delete(`/competitions/${id}`);
  };

  refreshTokens = () => {
    return this.client.post("/auth/refresh-tokens", {
      refreshToken: this.getRefreshToken(),
    });
  };

  setTokens = (accessToken, accessExpires, refreshToken, refreshExpires) => {
    let baseDomain = getDomainWithoutSubdomain(window.location.origin);
    let accessExpireDate = new Date(accessExpires);
    let refreshExpireDate = new Date(refreshExpires);

    document.cookie = `access_token=${accessToken}; domain=${baseDomain}; expires=${accessExpireDate}; path=/;`;
    document.cookie = `refresh_token=${refreshToken}; domain=${baseDomain}; expires=${refreshExpireDate}; path=/;`;
  };

  getAccessToken = () => {
    return (
      document.cookie.match("(^|;)\\s*access_token\\s*=\\s*([^;]+)")?.pop() ||
      ""
    );
  };

  getRefreshToken = () => {
    return (
      document.cookie.match("(^|;)\\s*refresh_token\\s*=\\s*([^;]+)")?.pop() ||
      ""
    );
  };
}
