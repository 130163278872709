import React, { useState } from "react";
import DatePicker from "../components/DatePicker";
import TimePickerComp from "../components/TimePicker";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CreateCompetitionHeader from "../components/CreateCompetitionHeader";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../utils/useLocalStorage.js";
import { PrimaryButton } from "../designSystem/Buttons";
import { BasicScreen } from "../designSystem/BasicScreen";
import { RouteLink } from "../designSystem/RouteLink";

const CompetitionDateTime = () => {
  const location = useLocation();
  let mode, id;
  if (location.state) {
    mode = location.state.mode;
    id = location.state.id;
  }
  let competitionObject = getFromLocalStorage("competition");
  let initialStartDate = competitionObject
    ? moment(competitionObject.starts)
    : moment();
  let initialEndDate = competitionObject
    ? moment(competitionObject.ends)
    : moment().add(1, "days");

  const [startDate, setStartDate] = useState(initialStartDate);
  const [startTime, setStartTime] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [endTime, setEndTime] = useState(initialEndDate);

  const handleClick = () => {
    let formattedStartDate = moment(
      `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm:ss")}`
    )
      .utc()
      .format();
    let formattedEndDate = moment(
      `${endDate.format("YYYY-MM-DD")}T${endTime.format("HH:mm:ss")}`
    )
      .utc()
      .format();

    if (!competitionObject) {
      competitionObject = {
        name: "",
        starts: formattedStartDate,
        ends: formattedEndDate,
      };
    } else {
      competitionObject["starts"] = formattedStartDate;
      competitionObject["ends"] = formattedEndDate;
    }

    setToLocalStorage("competition", competitionObject);
  };

  return (
    <BasicScreen
      header={
        <CreateCompetitionHeader
          text="Competition Date & Time"
          back="/competition_name"
        />
      }
    >
      <div className="body_cont d-flex flex-column ">
        <div className="competition_date_time_body container-fluid px-4">
          <div className="row gy-5">
            <div className="col-6">
              <DatePicker
                label="Start Date"
                setDate={setStartDate}
                date={startDate}
              />
            </div>
            <div className="col-6">
              <TimePickerComp
                label="Hour"
                setTime={setStartTime}
                time={startTime}
              />
            </div>
            <div className="col-6">
              <DatePicker
                label="End Date"
                setDate={setEndDate}
                date={endDate}
              />
            </div>
            <div className="col-6">
              <TimePickerComp
                label="Hour"
                setTime={setEndTime}
                time={endTime}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid px-4 my-4">
          <RouteLink to="/competition_products" state={{ mode: mode, id: id }}>
            <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
          </RouteLink>
        </div>
      </div>
    </BasicScreen>
  );
};

export default CompetitionDateTime;
