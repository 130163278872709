import { useState } from "react";
import { styled } from "@mui/material/styles";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { Button, Input } from "@mui/material";

const SearchContainer = styled("div")`
  position: absolute;
  right: 1rem;
  top: 0.9rem;
  width: ${(props) => (props.isSearching ? 360 : 48)}px;
  height: 48px;
  background: ${(props) => (props.isSearching ? "#e3e3e3" : "#fff")};
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  transition: all 0.3s ease;
`;

const SearchInput = styled("input")`
  padding-left: 48px;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  border: none;
  color: #383d42;
`;

const IconButton = styled(Button)`
  text-decoration: none;
  position: realtive;
  height: 36px;
  width: 36px;
  border: none;
  z-index: 1;
  cursor: pointer;
  background: none;
  color: #383d42;

  &:hover {
    background: none;
    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
    background: #000;
    transition: 0.2s ease;
    transform: scale(0.6);
    opacity: 0;
  }
`;

export default function SearchBar(props) {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const toggleSearch = () => {
    setIsActive(!isActive);
  };

  const handleUserInput = (e) => {
    setInputValue(e.target.value);
    props.onChange(e.target.value);
  };

  const resetSearch = () => {
    props.onChange("");
    setInputValue("");
  };

  return (
    <SearchContainer isSearching={isActive}>
      <IconButton onClick={toggleSearch}>
        {isActive ? (
          <AiOutlineClose size={18} onClick={resetSearch} />
        ) : (
          <AiOutlineSearch size={22} />
        )}
      </IconButton>
      <SearchInput
        placeholder="Type to search..."
        onChange={handleUserInput}
        value={inputValue}
      />
    </SearchContainer>
  );
}
